exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-infinite-js": () => import("./../../../src/templates/blog-post-infinite.js" /* webpackChunkName: "component---src-templates-blog-post-infinite-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-next-js": () => import("./../../../src/templates/blog-post-next.js" /* webpackChunkName: "component---src-templates-blog-post-next-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-index-lang-js": () => import("./../../../src/templates/index-lang.js" /* webpackChunkName: "component---src-templates-index-lang-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

